@tailwind base;
@tailwind components;
@tailwind utilities;

/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-family: "VT323", monospace;
  margin: 0;
}

html,
body {
  height: 100%;
  font-size: 1.15rem;
  accent-color: #c4fd60;
  color-scheme: dark;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  height: 100%;
  isolation: isolate;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

button:not(:disabled):focus-visible,
input:focus-visible {
  box-shadow: 0 0 0 calc(4px + 0.005rem) #ffffff69;
}
